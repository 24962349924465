export const iitcWidgetTemplateHtml = `
<style>
    :host {
      font-family: Porsche Next, "Segoe UI", sans-serif;
    }
    #iitc-widget-container {
       background-color: #EEEFF2;
       border-radius: 15px;
    }
    #widget-content {
      padding: 1.5rem;
      flex-direction: column;
    }
    #widget-button-container, #spinner-and-text {
      display: flex;
      align-items: center;
    }
    #success-icon-and-text {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
    #continue-button {
      align-self: baseline;
      margin-left: 32px;
    }
    #header {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
      line-height: 2rem;
    }
    #subheader {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    #description {
      margin-bottom: 1.5rem;
    }
    #go-to-button {
      align-self: baseline;
    }
    #iitc-widget-modal-provider-container {
      display: flex;
      gap: 0.5rem;
    }
    #iitc-widget-modal-info-loading {
      display: none;
       align-items: center;
      gap: 0.5rem;
    }
    #iitc-widget-modal-info-created {
      display: none;
      flex-direction: column;
      gap: 1rem;
    }
    #iitc-widget-modal-logo-href {
      display: flex;
      align-self: center;
    }  
    #iitc-widget-modal-logo {
      height: 1rem;
      align-self: center;
    }
    .display-flex {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    .display-grid {
      display: grid;
    }
    #iitc-widget-modal-error-wrapper {
      display: none;
    }
    #iitc-widget-modal-provider-container {
     margin-bottom: 1rem;
    }
    @media screen and (max-width: 480px) {
      #go-to-button {
        width: 100%;
      }
      #widget-button-container {
        flex-direction: column;
        gap: 1rem;
      }
    }
    
     @keyframes skeletonAnimation {
       from {
         background-position-x: 100%;
       }
       to {
         background-position-x: -100%;
       }
     }
</style>

<div id='iitc-widget-container' data-testid='widget-container'>
  <div id='widget-content' style='display: none'>
     <div id='header' translation-key='iitc.widget.header' data-testid='widget-header'></div>
     <div id='subheader' translation-key='iitc.widget.subheader' data-testid='widget-subheader'></div>
     <div id='description' translation-key='iitc.widget.description'></div>
     <div id='widget-button-container'>
       <p-button id='go-to-button' translation-key='iitc.widget.button' data-testid='widget-button'>{{providerName}}
       </p-button>
     </div>
  </div>
  <p-modal id='iitc-widget-modal' data-testid='iitc-widget-modal' open='false'>
      <div id='iitc-widget-modal-provider-container'>
         <div class='display-flex'>
          <div translation-key='iitc.widget.modal.provider' ></div>
          <a target='_blank' id='iitc-widget-modal-provider-name'  
               data-testid='iitc-widget-modal-provider-name'></a>
         </div>
        <p-divider direction='vertical'></p-divider>
          <a target='_blank' id='iitc-widget-modal-logo-href'>
            <img id='iitc-widget-modal-logo' data-testid='iitc-widget-modal-logo' />    
          </a>
      </div>
      <div class='display-grid'>
        <div id='iitc-widget-modal-info-loading' data-testid='iitc-widget-modal-info-loading'>
           <p-spinner size='small'></p-spinner>
           <p-text translation-key='iitc.widget.modal.content'></p-text>
        </div>
        <div id='iitc-widget-modal-info-created' data-testid='iitc-widget-modal-info-created'>
           <div id='success-icon-and-text'>
             <p-icon name='success'></p-icon>
             <p-text translation-key='iitc.widget.modal.content.created'></p-text>
           </div>
           <div>
             <p-button id='continue-button' translation-key='iitc.widget.continue.button' data-testid='continue-button'>
             </p-button>
           </div>
        </div>
        <div id='iitc-widget-modal-error-wrapper' data-testid='iitc-widget-modal-error-wrapper'>
          <p-inline-notification id='iitc-widget-modal-error' state='error' dismiss-button='false'/>
        </div>
      </div>
  </p-modal>
</div>
`
